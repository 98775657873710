<script setup>
    import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
    import { i18n } from '@/js/i18nController';

    // Создаем вычисляемое свойство для текущей локали
    const currentLocale = computed(() => i18n.global.locale.value);

    // Начальные значения для углов и шага
    const initialAngle = 67.5;
    const step = 45;

    // Реактивная переменная для хранения значения offset
    const offset = ref(calculateOffset());

    // Функция для вычисления offset на основе ширины экрана
    function calculateOffset() {
        const width = window.innerWidth;
        if (width <= 767 || (width >= 1366 && width <= 1899)) {
            return -118;
        } else {
            return -200;
        }
    }

    // Функция для обновления CSS переменной offset
    function updateOffset() {
        offset.value = calculateOffset();
    }

    // Отслеживание изменения размера окна и смены ориентации
    watch(
        () => window.innerWidth,
        () => {
            updateOffset();
        }
    );

    onMounted(() => {
        // Обновление при инициализации
        updateOffset();

        // Добавление слушателя resize и orientationchange
        window.addEventListener('resize', updateOffset);
        window.addEventListener('orientationchange', updateOffset);
    });

    onUnmounted(() => {
        // Удаление слушателя resize и orientationchange при размонтировании компонента
        window.removeEventListener('resize', updateOffset);
        window.removeEventListener('orientationchange', updateOffset);
    });

    // Функция для вычисления стилей сектора
    const getSectorStyle = (index) => {
        return {
            transform: `rotate(${initialAngle + step * index}deg) translateX(${
                offset.value
            }px)`,
        };
    };

    setTimeout(() => {
        updateOffset();
    }, 500);
</script>

<template>
    <div class="wheel">
        <div class="wheel__shadow"></div>
        <div class="wheel__bottom">
            <span :class="currentLocale">{{ $t('wheel_bottom_text') }}</span>
        </div>
        <div class="wheel__sectors">
            <div
                v-for="i in 8"
                :key="i"
                :class="`sector sector-${i} sector-${i}-${currentLocale}`"
                :style="getSectorStyle(i - 1)"
                dir="ltr"
            >
                {{ $t(`wheel_text.sector_${i}`) }}
            </div>
        </div>
        <div class="wheel__ring"></div>
        <div
            class="wheel__button spin"
            :class="currentLocale"
        >
            {{ $t('wheel_button') }}
        </div>
    </div>
</template>

<style lang="scss">
    @import '@/scss/variables.scss';
    @import '@/scss/mixins.scss';

    @import './Wheel.scss';
</style>
