import { createI18n } from 'vue-i18n';
import { reactive } from 'vue';

// Импортируйте ваши файлы локализации
import messages from '@/locales/index.js';

// Функция для получения предпочитаемого языка из localStorage или браузера
function getSavedLocale() {
    if (typeof window !== 'undefined' && window.localStorage) {
        const savedLocale = localStorage.getItem('preferred_locale');

        if (savedLocale && savedLocale in messages) {
            return savedLocale;
        }
    }
    return null;
}

// Определение предпочитаемого языка браузера или использование сохраненного в localStorage
const browserLocale =
    typeof navigator !== 'undefined' &&
    navigator.language &&
    typeof navigator.language === 'string'
        ? navigator.language.slice(0, 2).toLowerCase()
        : 'en';
const defaultLocale =
    getSavedLocale() || (browserLocale in messages ? browserLocale : 'en');

// Сохранение предпочитаемого языка в localStorage
if (typeof window !== 'undefined' && window.localStorage && defaultLocale) {
    localStorage.setItem('preferred_locale', defaultLocale);
}

// Создание реактивного свойства для текущей локали
const locale = reactive({
    current: defaultLocale,
});

// Функция для обновления текущей локали и сохранения в localStorage
function changeLocale(newLocale) {
    if (newLocale in messages) {
        locale.current = newLocale;
        if (typeof window !== 'undefined' && window.localStorage) {
            localStorage.setItem('preferred_locale', newLocale);
        }
    } else {
        console.warn(`Locale ${newLocale} is not available.`);
    }
}

// Установка локали по умолчанию и fallback локали
const i18n = createI18n({
    legacy: false, // Отключаем легаси-режим
    locale: locale.current,
    fallbackLocale: 'en',
    messages,
});

export { i18n, locale, changeLocale };
