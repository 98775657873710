<script setup>
    import {
        onMounted,
        onUnmounted,
        computed,
        watchEffect,
        ref,
        watch,
    } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { i18n } from '@/js/i18nController';
    import LanguageSwitcher from '@/components/LanguageSwitcher/LanguageSwitcher.vue';
    import Wheel from '@/components/Wheel/Wheel.vue';
    import { spinReel } from '@/js/wheelController';
    import { useHead } from '@vueuse/head';
    import { metaConfig } from '@/js/config/meta';
    import ModalFirst from '@/components/Modal/ModalFirst.vue';
    import ModalSecond from '@/components/Modal/ModalSecond.vue';

    // Создаем вычисляемое свойство для текущей локали
    const currentLocale = computed(() => i18n.global.locale.value);

    const { t } = useI18n();

    // Определяем направление текста на основе текущей локали
    const direction = computed(() => {
        const rtlLanguages = ['ar', 'fa']; // Языки с направлением текста справа налево
        return rtlLanguages.includes(currentLocale.value) ? 'rtl' : 'ltr';
    });

    // Создаем реактивную переменную для хранения значения currentSpin
    const currentSpin = ref(localStorage.getItem('currentSpin'));

    // Функция для обновления currentSpin и условного рендеринга модальных окон
    function updateCurrentSpin() {
        currentSpin.value = localStorage.getItem('currentSpin');
    }

    // Проверяем значение currentSpin через интервал
    let intervalId = null;

    onMounted(() => {
        // Обновляем currentSpin при монтировании компонента
        updateCurrentSpin();

        // Устанавливаем интервал для регулярной проверки значения currentSpin
        intervalId = window.setInterval(() => {
            updateCurrentSpin();
        }, 1000); // Проверка каждые 1 секунду
    });

    onUnmounted(() => {
        // Очищаем интервал при размонтировании компонента
        if (intervalId !== null) {
            window.clearInterval(intervalId);
        }
    });

    // Следим за изменением локали и обновляем currentSpin
    watch(currentLocale, () => {
        updateCurrentSpin();
    });

    // Используем watchEffect для динамического обновления title, description и других мета-тегов
    watchEffect(() => {
        useHead({
            htmlAttrs: {
                lang: currentLocale.value,
                dir: direction.value,
            },
            bodyAttrs: {
                dir: direction.value,
            },
            title: t('metadata.title') || metaConfig.title,
            meta: [
                {
                    name: 'description',
                    content:
                        t('metadata.description') || metaConfig.description,
                },
                ...metaConfig.metaTags,
            ],
            link: [
                {
                    rel: 'apple-touch-icon',
                    sizes: '180x180',
                    href: metaConfig.favicon.appleTouchIcon,
                },
                {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '32x32',
                    href: metaConfig.favicon.favicon32,
                },
                {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '16x16',
                    href: metaConfig.favicon.favicon16,
                },
                {
                    rel: 'manifest',
                    href: metaConfig.favicon.manifest,
                },
                {
                    rel: 'mask-icon',
                    href: metaConfig.favicon.safariPinnedTab,
                    color: '#5bbad5',
                },
                {
                    rel: 'shortcut icon',
                    href: metaConfig.favicon.faviconIco,
                },
            ],
        });
    });

    onMounted(() => {
        spinReel();
    });
</script>

<template>
    <div class="app">
        <div class="header container">
            <div class="logo">
                <img
                    src="@/img/logo.svg"
                    alt="logo"
                />
            </div>
            <LanguageSwitcher />
        </div>
        <div class="Zeus"></div>
        <div class="star"></div>
        <div class="star2"></div>
        <div class="chip">
            <div class="chip-star"></div>
            <div class="chip-star2"></div>
        </div>

        <Wheel />
        <div class="card"></div>

        <ModalFirst v-if="currentSpin === 'first'" />
        <ModalSecond v-if="currentSpin === 'second'" />
    </div>
</template>

<style>
    .spin {
        &:hover {
            cursor: pointer;
        }
    }
</style>
