// src/config/meta.js
export const baseFaviconPath = '/img/favicon';

export const metaConfig = {
    title: 'Wheel of Fortune',
    description:
        'Free spins, bonus funds, and more! Spin the wheel of fortune and bag amazing gifts!',
    favicon: {
        appleTouchIcon: `${baseFaviconPath}/apple-touch-icon.png`,
        favicon32: `${baseFaviconPath}/favicon-32x32.png`,
        favicon16: `${baseFaviconPath}/favicon-16x16.png`,
        manifest: `${baseFaviconPath}/site.webmanifest`,
        safariPinnedTab: `${baseFaviconPath}/safari-pinned-tab.svg`,
        faviconIco: `${baseFaviconPath}/favicon.ico`,
        browserConfig: `${baseFaviconPath}/browserconfig.xml`,
    },
    metaTags: [
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
        { name: 'msapplication-TileColor', content: '#da532c' },
        { name: 'theme-color', content: '#ffffff' },
        {
            name: 'msapplication-config',
            content: `${baseFaviconPath}/browserconfig.xml`,
        },
    ],
};
