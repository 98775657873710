<script setup lang="ts">
    import { VueFinalModal } from 'vue-final-modal';

    defineProps<{
        title?: string;
        bonus?: string;
        button?: string;
        spins?: number;
        link?: string;
    }>();

    const emit = defineEmits<{
        (e: 'confirm'): void;
    }>();

    const spinClass = localStorage.getItem('currentSpin');
</script>

<template>
    <VueFinalModal
        :clickToClose="false"
        :escToClose="false"
    >
        <div class="counter-spin">
            {{ $t('modals.counter_spin') }} {{ spins }}
        </div>
        <h1 class="text-xl">
            {{ title }}
        </h1>
        <div class="bonus">{{ bonus }}</div>
        <a
            class="btn"
            :href="link"
            target="_blank"
            @click="emit('confirm')"
        >
            <div class="star"></div>
            <div class="content">
                {{ button }}
            </div>
        </a>
    </VueFinalModal>
</template>

<style lang="scss">
    @import '@/scss/variables.scss';
    @import '@/scss/mixins.scss';

    .vfm {
        &__overlay {
            backdrop-filter: blur(2px);
            animation: backgroundChange 3s ease-in-out infinite;

            @include media-breakpoint('1024') {
                animation: backgroundChangeLarge 4s ease-in-out infinite;
            }
        }

        @keyframes backgroundChange {
            0% {
                background: rgba(6, 6, 44, 0.8) url('@/img/modal/bg-sm.svg')
                    no-repeat center;
            }
            50% {
                background: rgba(6, 6, 44, 0.8) url('@/img/modal/bg-sm-1.svg')
                    no-repeat center;
            }
            100% {
                background: rgba(6, 6, 44, 0.8) url('@/img/modal/bg-sm.svg')
                    no-repeat center;
            }
        }

        @keyframes backgroundChangeLarge {
            0% {
                background: rgba(6, 6, 44, 0.8) url('@/img/modal/bg-lg-1.png')
                    no-repeat center;
            }
            50% {
                background: rgba(6, 6, 44, 0.8) url('@/img/modal/bg-lg.png')
                    no-repeat center;
            }
            100% {
                background: rgba(6, 6, 44, 0.8) url('@/img/modal/bg-lg-1.png')
                    no-repeat center;
            }
        }

        &__content {
            padding-top: 20px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 200px;
            height: 145px;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: top;
            white-space: pre-line;
            text-align: center;
            font-style: normal;

            @include media-breakpoint('1024') {
                padding: 0;
                width: 530px;
                height: 380px;
            }

            .counter-spin {
                position: absolute;
                top: calc(-50vh + 50% + 20px);
                text-align: center;
                text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
                font-size: 20px;
                font-weight: 400;
                line-height: 130%;

                @include media-breakpoint('768') {
                    right: calc(-50vw + 50% + 40px);
                    font-size: 24px;
                }

                @include media-breakpoint('1024') {
                    top: calc(-50vh + 50% + 40px);
                }
            }

            h1.text-xl {
                font-size: 20px;
                font-weight: 700;
                line-height: 100%;
                margin: 14px 0 0;

                @include media-breakpoint('1024') {
                    font-size: 42px;
                    line-height: 130%;
                    margin-bottom: 30px;
                }
            }

            .bonus {
                font-size: 28px;
                font-weight: 800;
                line-height: 130%;
                margin-bottom: 6px;

                @include media-breakpoint('1024') {
                    font-size: 60px;
                    line-height: 130%;
                }
            }

            .btn {
                width: 100%;
                width: 130px;
                height: 40px;
                background: url('@/img/modal/button-sm.webp') no-repeat center;
                background-size: contain;
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 4px;
                padding: 10px 16px;
                box-sizing: border-box;
                cursor: pointer;

                @include media-breakpoint('1024') {
                    background: url('@/img/modal/button-lg.webp') no-repeat
                        center;
                    background-size: contain;
                    width: 280px;
                    height: 86px;
                    padding: 20px 30px;
                    column-gap: 8px;
                }

                .star {
                    width: 16px;
                    height: 16px;
                    background: url('@/img/modal/star-sm.webp') no-repeat center;
                    background-size: contain;

                    @include media-breakpoint('1024') {
                        width: 44px;
                        height: 44px;
                    }
                }

                .content {
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 100%;
                    text-transform: uppercase;

                    @include media-breakpoint('1024') {
                        font-size: 24px;
                    }
                }
            }
        }
    }
</style>
