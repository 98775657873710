// src/js/main.js
import 'vue-final-modal/style.css';
import '@/scss/main.scss';
import { createApp } from 'vue';
import App from '@/App.vue';
import { createHead } from '@vueuse/head';
import { createVfm } from 'vue-final-modal';
import { i18n } from '@/js/i18nController';

const app = createApp(App);
const head = createHead();
const vfm = createVfm();

app.use(head);
app.use(vfm);
app.use(i18n);
app.mount('#app');
