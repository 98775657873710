<script setup lang="ts">
    import { ref, onMounted, onUnmounted } from 'vue';
    import { useModal, ModalsContainer } from 'vue-final-modal';
    import ModalConfirm from './ModalWrapper.vue';
    import { useI18n } from 'vue-i18n';
    const { t } = useI18n();

    /** Извлекаем параметры из URL адресной строки, при помощи функции getParamsFromUrl заменяем их в URL для клиента при помощи функции updateUrlWithParams START **/
    import { getParamsFromUrl, updateUrlWithParams } from '@/js/utils/urlUtils';
    const updatedUrl = updateUrlWithParams(
        import.meta.env.VITE_BASE_URL,
        getParamsFromUrl(
            window.location.href,
            JSON.parse(import.meta.env.VITE_PARAMS)
        )
    );
    // console.log('updatedUrl => ', updatedUrl);
    /** END **/

    // Создаем модальное окно с использованием `vue-final-modal`
    const { open, close } = useModal({
        component: ModalConfirm,
        attrs: {
            title: t('modals.second.title'),
            bonus: t('modals.second.bonus'),
            button: t('modals.second.button_text'),
            spins: 0,
            link: updatedUrl,
            onConfirm() {
                close();
                localStorage.removeItem('currentSpin');
            },
        },
    });

    // Создаем реактивную переменную для хранения значения флага
    const localStorageFlag = ref(localStorage.getItem('currentSpin'));

    // Функция для проверки значения флага и открытия модального окна
    function checkFlag() {
        if (localStorageFlag.value === 'second') {
            open();
        }
    }

    // Проверяем значение флага через интервал
    let intervalId: number;

    onMounted(() => {
        intervalId = window.setInterval(() => {
            localStorageFlag.value = localStorage.getItem('currentSpin');
            checkFlag();
        }, 1); // Проверяем каждые 1 секунду
    });

    onUnmounted(() => {
        window.clearInterval(intervalId); // Очищаем интервал при размонтировании
    });
</script>

<template>
    <ModalsContainer />
</template>
