// urlUtils.js

/**
 * Извлекает параметры из URL-строки.
 *
 * @param {string} [url=window.location.href] - URL-строка, из которой нужно извлечь параметры.
 * @param {string[]} [paramNames=null] - Массив имен параметров, которые нужно извлечь. Если не указан, возвращаются все параметры.
 * @returns {Object} - Объект, содержащий пары "ключ-значение" для всех параметров или указанных параметров из URL.
 */
const getParamsFromUrl = (url = window.location.href, paramNames = null) => {
    const urlObj = new URL(url);

    if (Array.isArray(paramNames)) {
        const selectedParams = {};
        paramNames.forEach((name) => {
            if (urlObj.searchParams.has(name)) {
                selectedParams[name] = urlObj.searchParams.get(name);
            }
        });
        return selectedParams;
    }

    const params = {};
    urlObj.searchParams.forEach((value, key) => {
        params[key] = value;
    });

    return params;
};

/**
 * Обновляет параметры в URL на основе переданного объекта параметров.
 *
 * @param {string} url - Адрес URL, в котором необходимо обновить параметры.
 * @param {Object} params - Объект, содержащий ключи и значения параметров для обновления. Ключи - имена параметров, значения - новые значения.
 * @returns {string} - Новый URL с обновленными параметрами.
 */
const updateUrlWithParams = (url, params) => {
    const urlObj = new URL(url);

    // Обновляем параметры URL на основе объекта params
    Object.keys(params).forEach((paramName) => {
        urlObj.searchParams.set(paramName, params[paramName]);
    });

    return urlObj.toString();
};

export { getParamsFromUrl, updateUrlWithParams };
