// src/locales/index.js

// Используем динамический импорт для всех JSON файлов из папки locales
const modules = import.meta.glob('@/locales/*.json', { eager: true });

const messages = Object.fromEntries(
    Object.entries(modules).map(([key, value]) => {
        // Извлекаем название языка из пути файла
        const locale = key.match(/\/(\w+)\.json$/)[1];
        return [locale, value.default];
    })
);

export default messages;
